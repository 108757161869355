import { AddOns } from '@racemap/sdk/schema/billing/AddOns';
import type { PredictionParams } from '../types/events';
import { OneSecondInMillis } from './time';

export const DefaultLogoUrl = 'https://static-cdn.racemap.com/img/default_logo.png';

export const RentGPSTrackerFormUrl = 'https://go.racemap.com/rent-gps';
export const RentGPSTrackerInfoUrl = 'https://go.racemap.com/gps-tracker#rent-pro';
export const HIGH_RESOLUTION_THRESHOLD = OneSecondInMillis * 10;

export enum EventTypes {
  REGULAR = 'REGULAR',
  CONTEST = 'CONTEST',
  CONTEST_GROUP = 'CONTEST_GROUP',
  STAGE = 'STAGE',
  STAGE_GROUP = 'STAGE_GROUP',
}

export const AtomicEvents: Array<string> = [
  EventTypes.REGULAR,
  EventTypes.STAGE,
  EventTypes.CONTEST,
];

export enum VisibilityStates {
  DRAFT = 'DRAFT',
  UNLISTED = 'UNLISTED',
  LISTED = 'LISTED',
  PUBLIC = 'PUBLIC',
  ARCHIVED = 'ARCHIVED',
}

export const VisibilityLabels: Record<string, string> = {
  [VisibilityStates.DRAFT]: 'Draft',
  [VisibilityStates.UNLISTED]: 'Unlisted',
  [VisibilityStates.LISTED]: 'Listed',
  [VisibilityStates.PUBLIC]: 'Open',
  [VisibilityStates.ARCHIVED]: 'Archived',
};

export enum AuthorizationStates {
  NONE = 'NONE',
  PAID = 'PAID',
  PAID_FREE_LOADINGS = 'PAID_FREE_LOADINGS',
  FREE = 'FREE',
}

export enum TimingSystems {
  NONE = 'NONE',
  OTHER = 'OTHER',
  FEIBOT = 'FEIBOT',
  RACERESULT = 'RACERESULT',
  CHRONO_TRACK = 'CHRONO_TRACK',
}

export const TimingSystemLabels: Record<TimingSystems, string> = {
  [TimingSystems.NONE]: 'None',
  [TimingSystems.OTHER]: 'Other',
  [TimingSystems.FEIBOT]: 'Feibot',
  [TimingSystems.RACERESULT]: 'RACE RESULT',
  [TimingSystems.CHRONO_TRACK]: 'ChronoTrack',
};

export enum TrackAvailibilityStates {
  WITH_TRACK = 'WITH_TRACK',
  WITHOUT_TRACK = 'WITHOUT_TRACK',
}

export const TrackAvailibilityLabels = {
  [TrackAvailibilityStates.WITH_TRACK]: 'With Predefined Track',
  [TrackAvailibilityStates.WITHOUT_TRACK]: 'Without Predefined Track',
};

export enum DeviceClasses {
  Ghost = 'Ghost',
  Upload = 'Upload',
  Mobile = 'Mobile',
  Manual = 'Manual',
  Tracker = 'Tracker',
  TrackBox = 'TrackBox',
  Integration = 'Integration',
  Transponder = 'Transponder',
  FeibotTransponder = 'FeibotTransponder',
  TrackPingTransponder = 'TrackPingTransponder',
  ChronoTrackTransponder = 'ChronoTrackTransponder',
}

export const DeviceClassTypes = {
  Ghost: {},
  Upload: {},
  Mobile: {},
  Manual: {},
  Tracker: {},
  TrackBox: { Passive: 'Passive', Active: 'Active', Decoder: 'Decoder' },
  Integration: {},
  Transponder: { Passive: 'Passive', Active: 'Active' },
  FeibotTransponder: {},
  TrackPingTransponder: { Passive: 'Passive', Active: 'Active' },
  ChronoTrackTransponder: {},
};

export const TransponderClasses = [
  DeviceClasses.ChronoTrackTransponder,
  DeviceClasses.TrackPingTransponder,
  DeviceClasses.FeibotTransponder,
  DeviceClasses.Transponder,
];

export const DeviceClassLabels = {
  [DeviceClasses.Manual]: 'Manual',
  [DeviceClasses.Upload]: 'Upload',
  [DeviceClasses.Mobile]: 'Mobile',
  [DeviceClasses.Tracker]: 'Tracker',
  [DeviceClasses.TrackBox]: 'TrackBox',
  [DeviceClasses.Integration]: 'Integration',
  [DeviceClasses.Transponder]: 'Transponder',
  [DeviceClasses.FeibotTransponder]: 'FeibotTransponder',
  [DeviceClasses.TrackPingTransponder]: 'TrackPingTransponder',
  [DeviceClasses.ChronoTrackTransponder]: 'ChronoTrackTransponder',
};

export enum UnitType {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export enum MapType {
  SATELLITE = 'SATELLITE',
  TERRAIN = 'TERRAIN',
  HYBRID = 'HYBRID',
  ROADMAP = 'ROADMAP',
  DARK = 'DARK',
}

export enum DeviceStateFlag {
  DISQUALIFIED = 'DSQ',
  DIDNOTSTART = 'DNS',
  DIDNOTFINISH = 'DNF',
  NORANK = '-',
}

export enum Targets {
  DISTANCE = 'DISTANCE',
  DURATION = 'DURATION',
}

export enum PinToShadowTrackMode {
  NONE = 'NONE',
  MAGNETIC = 'MAGNETIC',
  FORCED = 'FORCED',
}

export enum FlagContent {
  STARTNUMBER = 'STARTNUMBER',
  NAME = 'NAME',
  STARTNUMBERANDNAME = 'STARTNUMBER_AND_NAME',
}

export enum TimeMode {
  RELATIVE = 'RELATIVE',
  ABSOLUTE = 'ABSOLUTE',
}

export enum PlayerInfoSheetTabKeys {
  INFO = 'INFO',
  ELEVATION = 'ELEVATION',
  FLOW = 'FLOW',
  STATS = 'STATS',
  TIMING = 'TIMING',
}

export const PlayerInfoSheetTabLabels = {
  [PlayerInfoSheetTabKeys.INFO]: 'Event Infos',
  [PlayerInfoSheetTabKeys.ELEVATION]: 'Elevation Chart',
  [PlayerInfoSheetTabKeys.FLOW]: 'Event Flow',
  [PlayerInfoSheetTabKeys.STATS]: 'Statistics',
  [PlayerInfoSheetTabKeys.TIMING]: 'Leaderboard',
};

export enum LeaderboardHeaderContent {
  NAME = 'NAME',
  ICON = 'ICON',
  DISTANCE = 'DISTANCE',
}

export const LeaderboardHeaderContentLabels = {
  [LeaderboardHeaderContent.NAME]: 'Name',
  [LeaderboardHeaderContent.ICON]: 'Icon',
  [LeaderboardHeaderContent.DISTANCE]: 'Distance',
};

export enum AppScreenKeys {
  PLAYER = 'PLAYER',
  LEADERBOARD = 'LEADERBOARD',
}

export const AppScreenLabels = {
  [AppScreenKeys.PLAYER]: 'Map',
  [AppScreenKeys.LEADERBOARD]: 'Leaderboard',
};

export const AddOnLabels: Record<AddOns, string> = {
  [AddOns.TIMING]: 'Leaderboard',
  [AddOns.MONITOR]: 'Monitor',
  [AddOns.TRACKER_RENTAL]: 'Tracker Rental',
  [AddOns.SPONSOR]: 'Sponsor Logo',
  [AddOns.API]: 'Data API',
  [AddOns.PREDICTIVE]: 'Predictive',
  [AddOns.KEYS]: 'Passcode & Keys',
  [AddOns.PARTICIPANTS]: 'Participants',
  [AddOns.HIGH_RESOLUTION]: 'High Resolution',
};

export const AddOnDocUrls: Record<AddOns, string> = {
  [AddOns.TIMING]: 'https://docs.racemap.com/how-to-use-live-tracking/gps-timing',
  [AddOns.MONITOR]: 'https://docs.racemap.com/how-to-use-live-tracking/monitor',
  [AddOns.TRACKER_RENTAL]: 'https://docs.racemap.com',
  [AddOns.SPONSOR]: 'https://docs.racemap.com',
  [AddOns.API]: 'https://docs.racemap.com/data-api',
  [AddOns.PREDICTIVE]: 'https://docs.racemap.com/predictive-tracking-with-data-from-timekeeping',
  [AddOns.KEYS]: 'https://docs.racemap.com/how-to-use-live-tracking/tab-keys',
  [AddOns.PARTICIPANTS]: 'https://docs.racemap.com/api-import-and-export-data',
  [AddOns.HIGH_RESOLUTION]: 'https://docs.racemap.com/high-resolution-maps',
};

export const numberOfActivationPeriod = 3;
export const unitOfActivationPeriod = 'month';

export enum ModulesStates {
  NONE = 'NONE',
  /** @deprecated modules should only be activated or not */
  ENABLED = 'ENABLED',
  ACTIVATED = 'ACTIVATED',
}

export enum LeaderboardType {
  DEFAULT = 'DEFAULT',
  EXTERNAL = 'EXTERNAL',
  RACE_RESULT = 'RACE_RESULT',
}

export enum RegistrationType {
  EXTERNAL = 'EXTERNAL',
  RACE_RESULT = 'RACE_RESULT',
}

export enum PanelState {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  PRIMARY = 'primary',
  NONE = 'secondary',
}

export const DefaultPredictionParams: PredictionParams = {
  // Parameter influencing the mapping
  // A reader location must be within this distance to the track
  distanceFromTrack: 40.0, // meters
  // Parameter influencing the interpolation allgorithm
  // the max speed in m/s that is used to correct faulty predictions in live mode
  maxCompensationSpeed: 100.0, // meters / second
  // minimum distance before we accept a new valid ping position -> bigger number decreases speed measuring errors
  minDistance: 60.0, // meters
  // all Pings below this value will be ignored
  rssiThreshold: -75.0, // dBm
  // At this point in future the simulated starter should meet the measured starter
  futureTrackScaleValue: 1.5,
  // We use this distance in meters to measure the speed of a starter for approximation
  lookBackDistance: 1000.0, // meters
  // we use this time interval to look into the future. In live mode we let the starters run lookForwardTimeInMs into the future. This should compensate for latency issues rising when pushing data through our system until it arrives in the player
  lookForwardTime: 15.0, // seconds
  // when the live prediction is to far away (timewise) from the last measured position we jump to the last measured position
  // we do a jump forward or backwards if the time difference is bigger than jumpingDotThresholdInMs
  jumpingDotThreshold: 360.0, // seconds
};
